<template>
  <div>
    <div id="map" style="width: 100%; height: 100%; border-radius: 4px"></div>
    <div
      style="
        position: absolute;
        width: 100%;
        height: 40px;
        top: 20px;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 4px;
        color: white;
        pointer-events: none;
      "
    >
      <span style="position: absolute; left: 20px">Map</span>
      <v-switch
        style="position: absolute; right: 20px; top: -22px; pointer-events: all;"
        v-model="markerSwitch"
        @change="toggleMarkers"
      ></v-switch>
      <button
        small
        color="secondary"
        style="position: absolute;right: 75px;top: -2px;pointer-events: all;"
        @click="toggleFullscreen"
      >
        <v-icon>{{ isFullscreen ? "mdi-fullscreen-exit" : "mdi-fullscreen" }}</v-icon>
      </button>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Loader } from "@googlemaps/js-api-loader";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import PlayoutMapPopup from "./PlayoutMapPopup.vue";

const loader = new Loader({
  apiKey: "AIzaSyDhzM14Txth7ED17gZzJMKNT5dMxel9v5o",
  libraries: ["visualization"],
  mapIds: ["1aebb066bd50a01f"],
});

export default {
  name: "PlayoutMap",
  props: {
    mapData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isFullscreen: false,
      map: null,
      heatmap: null,
      markerSwitch: false,
      heatmapMaxIntensity: 0,
      isMapLoaded: false,
      markers: [],
      markerCluster: null,
      infoWindow: null,
      mapOptions: {
        center: { lat: 54, lng: -3.75 },
        zoom: 5.6,
        disableDefaultUI: true,
        zoomControl: true,
        mapId: "1aebb066bd50a01f",
      },
    };
  },
  watch: {
    processedHeatmapData(newData) {
      if (this.heatmap) {
        this.heatmap.setData(newData);
      }
      if (this.markerSwitch && this.markers.length) {
        this.updateMarkers();
      }
    },
  },
  mounted() {
    this.isMapLoaded = false;
    loader
      .load()
      .then(async () => {
        const { Map, InfoWindow } = await google.maps.importLibrary("maps");
        const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker");

        this.map = this.displayMap();
        this.infoWindow = new InfoWindow({
          content: "",
          disableAutoPan: true,
        });
        this.isMapLoaded = true;
      })
      .catch((error) => {
        console.error("Failed to load Google Maps:", error);
      });
  },
  computed: {
    processedHeatmapData() {
      if (!this.mapData || !this.mapData.data || !this.isMapLoaded) {
        return [];
      }

      const data = this.mapData.data;
      const length = data.length;
      const processedData = new Array(length);

      for (let i = 0; i < length; i++) {
        const frame = data[i];
        processedData[i] = {
          location: new google.maps.LatLng(frame.location.lat, frame.location.lng),
          weight: frame.plays,
        };
      }

      if (
        this.mapData.maxIntensity != null &&
        this.mapData.maxIntensity > this.heatmapMaxIntensity
      ) {
        this.heatmapMaxIntensity = this.mapData.maxIntensity;
        if (this.heatmap) {
          this.heatmap.set("maxIntensity", this.heatmapMaxIntensity);
        }
      }

      return processedData;
    },
    medianPlays() {
      if (!this.mapData || !this.mapData.data || !this.isMapLoaded) {
        return 0;
      }

      const data = this.mapData.data;
      const length = data.length;
      const plays = new Array(length);

      for (let i = 0; i < length; i++) {
        const frame = data[i];
        plays[i] = frame.plays;
      }

      const sortedPlays = plays.sort((a, b) => a - b);
      const middleIndex = Math.floor(sortedPlays.length / 2);
      const medianPlays =
        sortedPlays.length % 2
          ? sortedPlays[middleIndex]
          : (sortedPlays[middleIndex - 1] + sortedPlays[middleIndex]) / 2;

      return medianPlays;
    },
  },
  methods: {
    toggleFullscreen() {
      const container = this.$el;
      if (!document.fullscreenElement) {
        if (container.requestFullscreen) {
          container.requestFullscreen();
        } else if (container.webkitRequestFullscreen) {
          container.webkitRequestFullscreen();
        } else if (container.msRequestFullscreen) {
          container.msRequestFullscreen();
        }
        this.isFullscreen = true;
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
        this.isFullscreen = false;
      }
    },
    toggleMarkers() {
      if (this.markerSwitch) {
        this.initializeMarkers();
        this.updateMarkers();
      } else if (this.markerCluster) {
        this.markerCluster.clearMarkers();
      }
    },
    displayMap() {
      const mapDiv = document.getElementById("map");
      const map = new google.maps.Map(mapDiv, this.mapOptions);

      this.heatmap = new google.maps.visualization.HeatmapLayer({
        data: this.processedHeatmapData,
        map: map,
        radius: 7.5,
        opacity: 0.8,
      });

      return map;
    },
    initializeMarkers() {
      if (this.markers.length === 0) {
        this.markers = this.createMarkers(this.mapData.data);
        this.markerCluster = new MarkerClusterer({ map: this.map, markers: this.markers });
      }
    },
    createMarkers(data) {
      return data.map((item) => {
        let markerLabel = item.formatDeliverName;

        if (markerLabel.length > 4) {
          markerLabel = markerLabel.split(' ')
            .filter(word => !['the', 'towers', 'tower', ','].includes(word.toLowerCase()) && word.length > 1)
            .map(word => word.replace(/[^a-zA-Z0-9]/g, ''))
            .reduce((a, b) => a.length <= b.length ? a : b);

          if (markerLabel.length > 4) {
            markerLabel = markerLabel.slice(0, 4);
          } else if (markerLabel.length < 4) {
            markerLabel = item.formatDeliverName.slice(0, 4);
          }
        }

        const marker = new google.maps.Marker({
          position: { lat: item.location.lat, lng: item.location.lng },
          label: {
            text: markerLabel,
            color: 'lightgray',
            fontSize: "12px",
            fontFamily: 'Roboto',
            fontWeight: 'bold'
          },
          title: item.formatDeliverName,
          icon: {
            url: "pins/frame-pin.svg",
            scaledSize: new google.maps.Size(40, 40),
          }
        });

        marker.addListener("click", () => {
          this.infoWindow.close();

          this.infoWindow.setContent(`<div id="popup-${item.id}"></div>`);
          this.infoWindow.open(this.map, marker);

          this.$nextTick(() => {
            new Vue({
              el: `#popup-${item.id}`,
              render: (h) =>
                h(PlayoutMapPopup, {
                  props: {
                    mapData: item,
                    medianPlays: this.medianPlays,
                  },
                }),
            });
          });
        });
        return marker;
      });
    },
    updateMarkers() {
      if (this.markerCluster) {
        this.markerCluster.clearMarkers();
        this.markers = this.createMarkers(this.mapData.data);
        this.markerCluster.addMarkers(this.markers);
      }
    },
  },
};
</script>

<style>
button.gm-ui-hover-effect span{
  background-color: white !important;
  width: 20px !important;
  height: 20px !important;
  margin: 4px !important;
  margin-left: 20px !important
}
.gm-style-iw-chr div {
  display: none !important;
}
.gm-style .gm-style-iw-tc::after {
  background-color: var(--v-sidebarColorLight-darken1)
}

.gm-style .gm-style-iw-c {
  background-color: var(--v-sidebarColorLight-darken1) !important; /* Change this to your desired grey color */
  color: white !important;
  padding: 0px;
}

.gm-style .gm-style-iw-d {
  background-color: #333 !important; /* Match the background color */
  overflow: auto !important;
}

/* Style for the close button */
.gm-style .gm-style-iw-t button {
  background-color: transparent !important;
  opacity: 1 !important;
  position: absolute !important;
  right: 0;
  height: 32px !important;
}

/* Style for the close button icon */
.gm-style .gm-style-iw button img {
  filter: invert(1) !important;
}

/* Remove the default white background from the content container */
.gm-style-iw-d::-webkit-scrollbar-track,
.gm-style-iw-d::-webkit-scrollbar-track-piece {
  background: var(--v-sidebarColorLight-darken1) !important;
  display: none !important;
}
</style>
