<template>
  <div class="map-popup">
    <!-- Header Section -->
    <div class="popup-header">
      <div class="location-badge">
        <span class="emoji">📍</span>
        <span class="location-name">{{ mapData.townName }} {{ mapData.postCodeName }}</span>
      </div>
    </div>

    <!-- Main Info Section -->
    <div class="popup-content">
      <!-- Primary Details -->
      <div class="primary-details">
        <h3>{{ mapData.formatDeliverName }}</h3>
        <div class="sub-details">
          {{ mapData.mediaOwnerName }}
        </div>
      </div>

      <!-- Stats Grid -->
      <div class="stats-grid">
        <div class="stat-item">
          <div class="stat-label">PLAYS</div>
          <div class="stat-value">{{ formatNumber(mapData.plays) }}</div>
        </div>
        <div class="stat-item">
          <div class="stat-label">IMPACTS</div>
          <div class="stat-value">{{ formatNumber(mapData.impacts) }}</div>
        </div>
      </div>

      <div class="stats-grid"  style="grid-template-columns: repeat(2, 1fr) !important; margin-top:-4px">
        <div class="stat-item">
          <div class="stat-label">IMP./PLAYS</div>
          <div class="stat-value">{{ formatNumber(impactsPerPlay) }}</div>
        </div>
        <div class="stat-item" :class="performanceClass">
          <div class="stat-label">PLAYS VS AVG.</div>
          <div class="stat-value">{{ performancePercentage }}%</div>
        </div>
      </div>

      <!-- Location Details -->
      <div class="location-details">
        <div class="coordinates">{{ formatCoordinates }}</div>
        <div class="map-links">
          <a :href="googleMapsUrl" target="_blank" class="map-link" title="Open in Google Maps">
            <span class="emoji">🗺️</span>
          </a>
          <a :href="streetViewUrl" target="_blank" class="map-link" title="Open in Street View">
            <span class="emoji">🚶</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PlayoutMapPopup',
  props: {
    mapData: {
      type: Object,
      required: true
    },
    medianPlays: {
      type: Number,
      required: true
    }
  },
  computed: {
    streetViewUrl() {
      const { lat, lng } = this.mapData.location;
      return `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${lat},${lng}&radius=500`;
    },
    googleMapsUrl() {
      const { lat, lng } = this.mapData.location;
      return `https://www.google.com/maps?q=${lat},${lng}`;
    },
    formatCoordinates() {
      const lat = this.mapData.location.lat.toFixed(6);
      const lng = this.mapData.location.lng.toFixed(6);
      return `${lat}°N, ${lng}°E`;
    },
    performancePercentage() {
      return Math.round((this.mapData.plays / this.medianPlays - 1) * 100)
    },
    performanceClass() {
      return {
        'performance-high': this.performancePercentage > 0,
        'performance-low': this.performancePercentage < -10,
        'performance-neutral': this.performancePercentage >= -10 && this.performancePercentage <= 0
      }
    },
    performanceEmoji() {
      if (this.performancePercentage > 10) return '📈'
      if (this.performancePercentage < -10) return '📉'
      return '➡️'
    },
    impactsPerPlay() {
      return this.mapData.impacts / this.mapData.plays
    }
  },
  methods: {
    formatNumber(num, detailed = false) {
      if (!num) return '0'
      if (detailed) {
        return Math.round(num).toLocaleString()
      }
      if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + 'M'
      }
      if (num >= 1000) {
        return (num / 1000).toFixed(1) + 'K'
      }
      return Math.round(num).toString()
    }
  }
}
</script>

<style scoped>
.map-popup {
  min-width: 100px;
  max-width: 400px;
  width: 100%;
  background: var(--v-sidebarColorLight-darken1);
  border-radius: 8px;
  overflow: hidden;
  font-size: 12px;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 8px;
  background: rgba(0, 0, 0, 0.345);
  color: white;
  min-height: 32px;
}

.location-badge {
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 500;
  flex: 1;
  min-width: 0;
}

.location-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.emoji {
  font-size: 1.25em;
  line-height: 1;
  flex-shrink: 0;
}

.performance-indicator {
  padding: 2px 4px;
  border-radius: 3px;
  font-weight: 600;
  font-size: 0.8em;
  margin-left: 4px;
  flex-shrink: 0;
  margin-right: 60px;
}

.performance-high { background-color: #136415 !important; }
.performance-low { background-color: #5f1813 !important; }
.performance-neutral { background-color: #54380d  !important; }

.popup-content {
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.primary-details {
  min-width: 0;
}

.primary-details h3 {
  margin: 0;
  color: #ffffff;
  font-size: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sub-details {
  color: #d3d3d3;
  font-size: 0.9em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4px;
}

.stat-item {
  text-align: center;
  padding: 4px;
  background: #00000048;
  border-radius: 4px;
  min-width: 0;
}

.stat-label {
  font-size: 0.9em;
  font-weight: 500;
  color: #b8b8b8;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.stat-value {
  font-weight: 600;
  color: #a2a2a2;
  font-size: 0.9em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.location-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  border-top: 1px solid #404040;
  padding-top: 8px;
  margin-top: 4px;
}

.coordinates {
  color: #bebebe;
  font-family: monospace;
  font-size: 0.8em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}

.map-links {
  display: flex;
  gap: 4px;
  flex-shrink: 0;
}

.map-link {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--v-primary-base);
  text-decoration: none;
  background: #00000068;
  padding: 4px;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.map-link:hover {
  background: #e0e0e0;
}

@media (max-width: 200px) {
  .popup-content {
    padding: 4px;
    gap: 4px;
  }

  .stats-grid {
    font-size: 0.9em;
  }

  .coordinates {
    font-size: 0.7em;
  }

  .emoji {
    font-size: 0.9em;
  }
}
</style>
