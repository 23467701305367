<template>
  <apexchart
    v-if="brushData && brushData.categories && brushData.categories.length"
    ref="brushChart"
    type="line"
    :options="brushChartOptions"
    :series="brushData.series"
    height="100%"
    width="100%"
  >
  </apexchart>
</template>

<script>
import apexchart from 'vue-apexcharts';
import debounce from 'lodash/debounce';

export default {
  name: 'PlayoutBrushChart',
  components: {
    apexchart,
  },
  props: {
    brushData: {
      type: Object,
      required: true,
      default: () => ({
        categories: [],
        series: []
      })
    },
    dateTimeRange: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  data() {
    return {
      lastEmittedSelection: null,
      currentSelection: null,
      chart: null,
      emitFuse: false,
      isUserInteraction: false, // Flag to track user interaction
    };
  },
  computed: {
    minDateTime() {
      if (!this.brushData?.categories?.length) return null;
      return this.brushData.categories[0].split('T')[0];
    },
    maxDateTime() {
      if (!this.brushData?.categories?.length) return null;
      return this.brushData.categories[this.brushData.categories.length - 1].split('T')[0];
    },
    minMaxDateTime() {
      if (this.dateTimeRange && this.dateTimeRange.start && this.dateTimeRange.end) {
        return [
          this.toMilliseconds(this.dateTimeRange.start),
          this.toMilliseconds(this.dateTimeRange.end)
        ];
      }
      if (!this.minDateTime || !this.maxDateTime) {
        return [null, null];
      }
      return [this.minDateTime, this.maxDateTime];
    },
    brushChartOptions() {
      const categories = this.brushData?.categories || [];
      
      return {
        chart: {
          id: 'brushChart',
          background: 'transparent',
          type: 'line',
          brush: {
            target: 'mainChart',
            enabled: true,
          },
          selection: {
            enabled: true,
            xaxis: {
              min: this.minMaxDateTime[0],
              max: this.minMaxDateTime[1],
            },
            fill: {
              color: '#4c4c4c',
              opacity: 0.7
            },
            stroke: {
              color: '#ffffff',
              width: 2,
              dashArray: 0
            }
          },
          events: {
            selection: this.onSelection,
            initialized: this.onChartInitialized,
          },
        },
        xaxis: {
          type: 'datetime',
          categories: categories,
          min: this.minDateTime || undefined,
          max: this.maxDateTime || undefined,
          labels: {
            formatter: function(value, timestamp) {
              const date = new Date(timestamp);
              return `${date.getDate()}`;
            },
            datetimeUTC: true,
            style: {
              fontSize: '8px',
            },
          },
          tickAmount: 'dataPoints',
        },
        yaxis: {
          show: false,
        },
        title: {
          show: false,
        },
        grid: {
          borderColor: 'var(--v-sidebarColorLight-lighten1)',
        },
        stroke: {
          width: 4,
          colors: ['var(--v-primary-base)'],
        },
        theme: {
          mode: 'dark',
        },
        fill: {
          opacity: 0.1
        },
        tooltip: {
          x: {
            format: 'dd MMM yyyy'
          }
        }
      };
    },
  },
  watch: {
    dateTimeRange: {
      handler(newRange, oldRange) {
        if (newRange && newRange.start && newRange.end &&
            (!oldRange || newRange.start !== oldRange.start || newRange.end !== oldRange.end)) {
          this.$nextTick(() => {
            this.isUserInteraction = false; // Indicate this is a programmatic update
            this.updateChartSelection(newRange.start, newRange.end);
          });
        }
      },
      deep: true
    }
  },
  created() {
    this.onSelectionDebounced = debounce(this.emitSelectionChanged, 10);
  },
  methods: {
    onChartInitialized(chart) {
      this.chart = chart;
    },
    onSelection(chartContext, { xaxis }) {
      this.isUserInteraction = true; // Set flag for user interaction
      this.currentSelection = [xaxis.min, xaxis.max];
      if (this.isSelectionChanged(this.currentSelection)) {
        this.onSelectionDebounced(this.currentSelection);
      }
    },
    isSelectionChanged(newSelection) {
      return (
        !this.lastEmittedSelection ||
        newSelection[0] !== this.lastEmittedSelection[0] ||
        newSelection[1] !== this.lastEmittedSelection[1]
      );
    },
    emitSelectionChanged(newSelection) {
      if (!this.emitFuse || !this.isUserInteraction) {
        this.emitFuse = true;
        return;
      }
      const roundedSelection = [
        this.roundToDay(new Date(newSelection[0]), 'min'),
        this.roundToDay(new Date(newSelection[1]), 'max')
      ];
      this.$emit('selectionChanged', roundedSelection);
      this.lastEmittedSelection = newSelection;
    },
    roundToDay(date, type) {
      date.setHours(0, 0, 0, 0);
      return date.toISOString();
    },
    updateChartSelection(startDate, endDate) {
      if (this.chart) {
        const newOptions = {
          chart: {
            selection: {
              xaxis: {
                min: this.toMilliseconds(startDate),
                max: this.toMilliseconds(endDate)
              }
            }
          }
        };
        
        this.chart.updateOptions(newOptions, false, false);

        if (this.currentSelection) {
          this.chart.setSelection({
            xaxis: {
              min: this.currentSelection[0],
              max: this.currentSelection[1]
            }
          }, false);
        }
      }
    },
    toMilliseconds(dateString) {
      return new Date(dateString).getTime();
    },
    toISOString(milliseconds) {
      return new Date(milliseconds).toISOString();
    }
  },
};
</script>