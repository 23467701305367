<template>
  <apexchart type="line" :options="mainChartOptions" :series="masterData.series" height="100%" width="100%"></apexchart>
</template>

<script>
// Services
import apexchart from 'vue-apexcharts'

export default {
  name: 'PlayoutMasterChart',
  props: {
    masterData: {
      type: Object,
      required: true
    }
  },
  data: () => ({

  }),
  components: {
    apexchart
  },
  mounted() {
  },
  computed: {

    mainChartOptions() {
      const formatNumber = (num, isSelectionLabel) => {
        if (num === null || num === undefined) return '0';
        if (isSelectionLabel) {
          return Math.round(num).toLocaleString();
        } else {
          if (num >= 1000000) {
            return (num / 1000000).toFixed(1) + 'M';
          } else if (num >= 1000) {
            return (num / 1000).toFixed(1) + 'K';
          } else {
            return num.toString();
          }
        }
      };

      const formatTooltipDate = (timestamp) => {
        const date = new Date(timestamp);
        // Format the date to local timezone (BST for UK)
        return date.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        });
      };
      let res = {
        chart: {
          type: 'line',
          background: 'var(--v-sidebarColorLight-darken2)',
          toolbar: {
            show: true,
          },
        },
        theme: {
          mode: 'dark',
        },
        dataLabels: {
          enabled: false,
        },
        labels: this.masterData.categories,
        xaxis: {
          type: 'datetime'
        },
        yaxis: [
          {
            title: {
              text: "Daily"
            },
            labels: {
              formatter: function (value) {
                return formatNumber(value, false);
              }
            }
          },
          {
            opposite: true,
            title: {
              text: "Cumulative"
            },
            labels: {
              formatter: function (value) {
                return formatNumber(value, false);
              }
            }
          }
        ],
        tooltip: {
          x: {
            formatter: function (value) {
              return formatTooltipDate(value);
            }
          },
          y: {
            formatter: function (value) {
              return formatNumber(value, true);
            }
          }
        }
      };

      return res;
    }


  },

  methods: {
  }
}
</script>